<template>
  <div>
<!--    <b-row>
      <b-col md="8">
        <b-card title="Composition du stock">
          <b-row>
            <b-col>
              <b-media no-body>
                <b-media-aside

                    class="mr-2"
                >
                  <b-avatar
                      size="48"
                      variant="light-danger"
                  >
                    <feather-icon
                        size="24"
                        icon="Eyeicon"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body class="my-auto">
                  <h4 class="font-weight-bolder mb-0">
                    {{dashboard_data.vehicule_en_stock}}
                  </h4>
                  <b-card-text class="font-small-3 mb-0">
                    Véhicules en stock
                  </b-card-text>
                </b-media-body>
              </b-media>
            </b-col>
            <b-col>
              <b-media no-body>
                <b-media-aside

                    class="mr-2"
                >
                  <b-avatar
                      size="48"
                      variant="light-info"
                  >
                    <feather-icon
                        size="24"
                        icon="EyeIcon"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body class="my-auto">
                  <h4 class="font-weight-bolder mb-0">
                    {{dashboard_data.en_prevision_entree}}
                  </h4>
                  <b-card-text class="font-small-3 mb-0">
                    En prévision de rentrée dans les 30 prochains jours
                  </b-card-text>
                </b-media-body>
              </b-media>
            </b-col>
          </b-row>
  
          
        </b-card>
        <b-row class="match-height">
          <b-col md="6">
            <b-card title="Répartition par canal de vente">
              <vue-apex-charts
                  type="donut"
                  height="250"
                  :options="{
                  legend: {
          show:true,
        
        },
          labels: ['Sans canal', 'Particuliers', 'Marchand'],
          
          stroke: {
            lineCap: 'round',
          },
          chart: {
      
            dropShadow: {
              enabled: true,
              blur: 3,
              left: 1,
              top: 1,
              opacity: 0.1,
            },
          },
        }"
                  :series="[70, 52, 26]"
              />

            </b-card>
          </b-col>
          <b-col md="6">
           <b-card title="Répartition par âge du stock">
             <vue-apex-charts
                 type="bar"
                 height="350"
                 :options="{
      chart: {
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: false,
        curve: 'straight',
      },
      legend: {
        show: true,
        position: 'top',
        horizontalAlign: 'left',
        fontSize: '14px',
        fontFamily: 'Montserrat',
      },
      grid: {
        xaxis: {
          lines: {
            show: true,
          },
        },
      },
      xaxis: {
        categories: [
          '7/12',
          '8/12',
          '9/12',
          '10/12',
          '11/12',
          '12/12',
          '13/12',
          '14/12',
          '15/12',
          '16/12',
          '17/12',
          '18/12',
          '19/12',
          '20/12',
        ],
      },
      yaxis: {
        // opposite: isRtl
      },
      fill: {
        opacity: 1,
        type: 'solid',
      },
      tooltip: {
        shared: false,
      },
     
    }"
                 :series="[
      {
 data: [100, 120, 90, 170, 130, 160, 140, 240, 220, 180, 270, 280, 375],
      },
    ]"
             />
           </b-card>
          </b-col>
        </b-row>
      </b-col>
      <b-col md="4">
        <b-card title="Optimisation du stock">
          <div v-for="item in dashboard_data.optimisation_stock" class="transaction-item border-bottom pb-1">
            <div class="transaction-title pr-1">{{item.label}}</div>
            <div class="font-weight-bolder text-success">{{item.value}}</div>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="5">
        <b-card title="Commande en cours de livraison">
          <div class="d-flex justify-content-between">
            <b-media no-body>
              <b-media-aside

                  class="mr-2"
              >
                <b-avatar
                    size="48"
                    variant="light-danger"
                >
                  <feather-icon
                      size="24"
                      icon="Eyeicon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  4040
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  Véhicules en cours de livraison
                </b-card-text>
              </b-media-body>
            </b-media>
            <b-media no-body>
              <b-media-aside

                  class="mr-2"
              >
                <b-avatar
                    size="48"
                    variant="light-danger"
                >
                  <feather-icon
                      size="24"
                      icon="Eyeicon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  781
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  Véhicules livrés
                </b-card-text>
              </b-media-body>
            </b-media>
          </div>
        </b-card>
      </b-col>
      <b-col md="7">
        <b-card title="Reporting Octobre 2022">
          <div class="d-flex justify-content-between">
            <b-media no-body>
              <b-media-aside

                  class="mr-2"
              >
                <b-avatar
                    size="48"
                    variant="light-danger"
                >
                  <feather-icon
                      size="24"
                      icon="Eyeicon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  4040
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  offres en cours
                </b-card-text>
              </b-media-body>
            </b-media>
            <b-media no-body>
              <b-media-aside

                  class="mr-2"
              >
                <b-avatar
                    size="48"
                    variant="light-danger"
                >
                  <feather-icon
                      size="24"
                      icon="Eyeicon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  781
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  bons de commande clôturés
                </b-card-text>
              </b-media-body>
            </b-media>
            <b-media no-body>
              <b-media-aside

                  class="mr-2"
              >
                <b-avatar
                    size="48"
                    variant="light-danger"
                >
                  <feather-icon
                      size="24"
                      icon="Eyeicon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  781
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  véhicules vendus
                </b-card-text>
              </b-media-body>
            </b-media>
          </div>
        </b-card>
      </b-col>
    </b-row>-->
  
    
    <b-row>
    <b-col>
      <div class="text-right mb-1">
        <b-button  @click="exportHandler" variant="primary">Exporter</b-button>  
      </div>
      
    </b-col>
  </b-row>
    <b-row>
     
    
 

    </b-row>
    <b-row>
      <b-col>
       <b-card>
     
         <b-row class="mb-1">
           <b-col>
             <b-form inline>
               <b-form-group label="Choisir période" content-cols-md="6" label-cols="auto" content-cols-sm="12">
                 <flat-pickr
                     v-model="du"
                     class="form-control"
                     :config="{  dateFormat: 'd/m/Y', locale: locale.fr, parseDate : true}"
                 />
                 
               </b-form-group>
               <b-form-group label=" Au" class="ml-1" content-cols-md="6" label-cols="auto" content-cols-sm="12">
                 <flat-pickr
                     v-model="au"
                     class="form-control"
                     :config="{ dateFormat: 'd/m/Y', locale: locale.fr, parseDate : true}"
                 />

               </b-form-group>

               <b-form-group class="ml-1" v-if="true || $can('consulter-dashboard-global', 'dashboard')" label="Concession" content-cols-md="6" label-cols="auto" content-cols-sm="12">

                 <v-select style="width: 200px;" :reduce="item => item.id" v-model="concession_id" label="libelle" :options="$store.state.initialConfigModule.initialState.concessions" placeholder="Rechercher par concession"></v-select>
               </b-form-group>
               
               
               <b-button  @click="fetch" class="ml-2">Actualiser</b-button>
               <b-button @click="au = null; du = null;fetch()" class="ml-2">Réinitialiser</b-button>
             </b-form>
        
           </b-col>
       
           
         </b-row>
         
         
         <table class="table">
           <thead>
           <tr>
             <th>Point de Vente</th>
             <th>Nbre d'expertise</th>
             <th>Reprises VO - VN</th>
             <th>Reprises VO - VO</th>
             <th>Achats Secs</th>
             <th>Stock VO</th>
             <th>Stock VO disponible à la vente</th>
             <th>Ventes VO</th>
           </tr>
           </thead>
           <tbody>
            <tr v-for="(item, site_id) in data">
              <td>{{item.site_name}}</td>
              <td>{{item.nbr_expertise }}</td>
              <td>{{item.reprise_vo_vn }}</td>
              <td>{{item.reprise_vo_vo}}</td>
              <td>{{item.achat_direct }}</td>
              <td>{{item.stock_vo }}</td>
              <td>{{item.disponible_a_la_vente}}</td>
            
           
            
            
              <td>{{item.vendu}}</td>
          
              
            </tr>
           </tbody>
         </table>
       </b-card>
      </b-col>
    </b-row>

  </div>
</template>

<script>
import {BCard, BCardText, BLink} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import axios from "@/libs/axios";
import flatPickr from 'vue-flatpickr-component'
import fr from 'flatpickr/dist/l10n/fr.js';
export default {
  components: {
    BCard,
    BCardText,
    BLink,
    VueApexCharts,
    flatPickr
  },
  data(){
      return {
        data : false,
        rangeDate : null,
        du : null,
        au : null,
        concession_id : null,
        locale: fr,
        dashboard_data : {
          vehicule_en_stock : 200,
          en_prevision_entree : 124,
          optimisation_stock : [
            {label : 'En stock depuis plus de 90 jours', value : '1000'},
            {label : 'Avec des informations manquantes', value : '1000'},
            {label : 'Informations bloquantes', value : '1000'},
            {label : 'Sans prix d\'annonce\n', value : '1000'},
            {label : 'Prix d\'annonce non mis à jour depuis\n' +
                  'plus de 30 jours\n', value : '1000'},
            {label : 'Complétude parcours AutoFocuse / Avec\n' +
                  'moins de 5 photos\n', value : '1000'},
            {label : 'Non-Publiable\n', value : '1000'},
          ]
        },
       
      }
  },
  created() {
    this.fetch();
  
  },
  methods : {
    fetch(){
      let self = this;
      this.$store.dispatch('dashboardModule/fetchDashboard', {
        rangeDate : this.du && this.au ? this.du + ' au ' + this.au : null,
        concession_id : this.concession_id,
        user_id : JSON.parse(localStorage.getItem('userData')).id
      }).then(function (response) {
        self.data = response.data;
      });
    },
    exportHandler(){
      let _this = this;
      _this.pendindExport = true;
      console.log(_this.$store.state);
      axios.get(
          process.env.VUE_APP_API_URL + 'api/dashboard-data-by-site',
          {
            responseType: 'blob',
            params : {
              concession_id : _this.concession_id,
              rangeDate : this.du && this.au ? this.du + ' au ' + this.au : null,
              user_id : JSON.parse(localStorage.getItem('userData')).id
            }
            
          } // !!!
      ).then((response) => {
        _this.pendindExport = false;
        window.open(URL.createObjectURL(response.data));
      });
    },
  }
}
</script>

<style>
  .transaction-item{
    margin-bottom: 12px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>